"use strict";

// 本のカスタムリンク表示
export const BOOK_SORT_ORDER_CUSTOM = 5;

// 子カテゴリのカスタムリンク表示
export const CHILD_SORT_ORDER_CUSTOM = 3;

// カテゴリの余幅（調整値）
export const CATEGORY_MARGIN_WIDTH = 1;

// 詳細表の高さの基準値
export const DEFAULT_IMAGE_HEIGHT = 1050;

// アニメーションの時間設定（ミリ秒）
export const ANIMATION_DURATION = {
  POSITION: 800,
  CATEGORY_OPEN: 200,
  TIMEOUT: 10000,
};

// 多階層種別
export const MULTI_LAYER = Object.freeze({
  CATEGORY: 1, // カテゴリ（索引）
  BOOK: 6, // ブック（背表紙）
});

// moveAnimation(), getElementAtPosition() で使用する位置種別
export const ELEMENT_POSITION = Object.freeze({
  CENTER: 0,
  LEFT: 1,
  RIGHT: 2,
  CUSTOM: 3,
});
